// Global Variables - WordPress Theme information
var WPTHEME = '/wp-content/themes/rainforgrowth-v2/';
var DOMAIN = location.protocol + "//" + location.host;

// Global - Google Tag Manager
window.dataLayer = window.dataLayer || [];

// jQuery Document Ready
var $ = jQuery;
jQuery(document).ready(function($) {
    //This preload class could be added to prevent the transition flashing noticed in google chrome. If added, this will remove so that the site transtions as expected.
    $("body").removeClass("preload");

    //
    // Global
    //
    let resized;
    let _windowWidth = $(window).outerWidth();
    let aboutUsImpetus = false;
    let careersCultureImpetus = false;
    let owdDragImpetus = false;
    const $root = $('html, body');
    let globalScrollX;

    window.onresize = function () { //check for resize every 100ms on resize event to prevent spamming js
        clearTimeout(resized);
        resized = setTimeout(resizedw, 100);
    }

    function resizeWidthOnly() { //return new window width if different than previous else return false. This makes sure height changes don't trigger resize events
        let currentWindowWidth = $(window).outerWidth();
        if (_windowWidth !== currentWindowWidth) {
            _windowWidth = currentWindowWidth;
            return _windowWidth;
        } else {
            return false;
        }
    }

    function resizedw() {
        if (resizeWidthOnly() !== false) {
            dynamicHeaderOffset();
            hideAllNavElements();
            caseStudiesImageSwiperOptionsF();
            testimonalSwiperOptionsF();
            clientsWeveScaledImageScroll();
            initializeAboutUsDraggableContent();
            mapSceneTrigger();
            setCrewScenes();
            setArticleDetailSocialPin();
            initializeDraggableContent();
            initializeOWDDraggableContent();
            globalScroll();
            setVideosLeftCopyPin();
            setTimelinePin();
            pinningImageCarouselPin();
            scrollbarNavigationSwiper();
            adjustHeaderHeightForEyebrow();
        }
    }

    // Dynamic Eyebrow/Header height
    let headerHeight = 100;
    let headerHeightSticky = 64;
    function adjustHeaderHeightForEyebrow() {
        if ($(".show-eyebrow").length) {
            let eyebrowHeight = $(".rain-header-eyebrow").outerHeight();
            let setHeightNormal = headerHeight + eyebrowHeight;
            let setHeightSticky = headerHeightSticky + eyebrowHeight;
            let rainNav;

            if ($(".rain-header-sticky").length) {
                rainNav = $(".show-eyebrow .rain-header-sticky .rain-header-nav");
                rainNav.attr("style", "height: calc(100vh - " + setHeightSticky + "px);height: calc(100dvh - " + setHeightSticky + "px)");
            } else {
                rainNav = $(".show-eyebrow .rain-header-nav");
                rainNav.attr("style", "height: calc(100vh - " + setHeightNormal + "px);height: calc(100dvh - " + setHeightNormal + "px)");
            }
        }
    }

    adjustHeaderHeightForEyebrow();

    // Smooth scrolling for anchor link
    function smoothScroll() {
        $(document).on("click", 'a[href^="#"]', function () {
            if ($.attr(this, 'href').length > 1) {
                $root.animate({
                    scrollTop: $($.attr(this, 'href')).offset().top - 64
                }, 300);
            }
            hideSelectmenu();

            if ($(this).closest("nav").hasClass("rain-header-lp-nav")) { //special functionality for landing page nav scrolling links
                hideAllNavElements();
            }

            return false;
        });
    }

    smoothScroll();

    //Fade Up
    let controller = new ScrollMagic.Controller();
    // let controller = new ScrollMagic.Controller({addIndicators: true});

    [].slice.apply(document.querySelectorAll(".fade-up")).forEach(function (el) {
        let opts = {triggerElement: el, triggerHook: 1, offset: 20},
            scene = new ScrollMagic.Scene(opts).addTo(controller);
        scene.on("start end", function (event) {
            el.classList.add("in-view");
        }).on("start", function() {
            // global countup for elements with fade-up class
            setTimeout(function() {
                let _thisCounterCount = $(el).find(".counter").data("countto");
                if (_thisCounterCount) {
                    new countUp.CountUp($(el).find(".counter")[0], _thisCounterCount).start();
                }
            }, 300);
        });
    });

    [].slice.apply(document.querySelectorAll(".fade-up-children")).forEach(function (el) {
        let opts = {triggerElement: el, triggerHook: 1, offset: 20},
            scene = new ScrollMagic.Scene(opts).addTo(controller);
        scene.on("start end", function (event) {
            el.classList.add("in-view");
        });
    });

    //Parallax Copy
    [].slice.apply(document.querySelectorAll(".parallax-copy")).forEach(function (el) {
        let opts = {triggerElement: el, triggerHook: "onEnter", duration: "125%"},
            scene = new ScrollMagic.Scene(opts).setTween(el, {x: "-25%", ease: Linear.easeNone}).addTo(controller);
    });

    [].slice.apply(document.querySelectorAll(".parallax-copy-reverse")).forEach(function (el) {
        let opts = {triggerElement: el, triggerHook: "onEnter", duration: "125%"},
            scene = new ScrollMagic.Scene(opts).setTween(el, {x: "25%", ease: Linear.easeNone}).addTo(controller);
    });

    //Parallax background
    [].slice.apply(document.querySelectorAll(".parallax-parent")).forEach(function (el) {
        let opts = {triggerElement: el, triggerHook: "onEnter", duration: "175%"},
            scene = new ScrollMagic.Scene(opts).setTween($(el).find(".parallax-parent-img"), {
                y: "80%",
                ease: Linear.easeNone
            }).addTo(controller);

        $(el).fadeIn();
    });

    //Parallax rotated items
    [].slice.apply(document.querySelectorAll(".parallax-rotated")).forEach(function (el) {
        let opts = {triggerElement: el, triggerHook: "onEnter", duration: "150%"},
            scene = new ScrollMagic.Scene(opts).setTween(el, {y: "-50%", ease: Linear.easeNone}).addTo(controller);
    });

    function effectTarget(effectParams, $slideEl) {
        if (effectParams.transformEl) {
            return $slideEl.find(effectParams.transformEl).css({
                'backface-visibility': 'hidden',
                '-webkit-backface-visibility': 'hidden'
            });
        }

        return $slideEl;
    }

    function setBlurEffect(_swiper, _blur) {
        _swiper.on("progress", function (swiper) {
            const params = swiper.params.creativeEffect;
            const {
                progressMultiplier: multiplier
            } = params;

            for (let i = 0; i < swiper.slides.length; i += 1) {
                const $slideEl = swiper.slides.eq(i);
                const progress = Math.min(Math.max($slideEl[0].progress, -params.limitProgress), params.limitProgress);
                let originalProgress = progress;

                let data = {
                    translate: [0, 0, 0]
                }

                if (progress < 0) {
                    data = params.next;
                } else if (progress > 0) {
                    data = params.prev;
                }

                const opacityString = originalProgress < 0 ? 1 + (1 - data.opacity) * originalProgress * multiplier : 1 - (1 - data.opacity) * originalProgress * multiplier;

                const $targetEl = effectTarget(params, $slideEl);

                $targetEl.transform().css("filter", "blur(" + (1 - opacityString) * _blur + "px)"); //gives blur effect on next slide and gradually lowers as slide comes into the frame
            }
        });
    }

    $.fn.extend({
        toggleText: function (a, b) {
            return this.text(this.text() == b ? a : b);
        }
    });

    //full width swiper (demo on the our work ego page)
    let fullWidthCenteredSwiper = new Swiper(".rainSwiperFullCenter", {
        slidesPerView: "auto",
        spaceBetween: 15,
        centeredSlides: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            768: {
                spaceBetween: 30
            }
        }
    });

    //scroll with page (demo on the our work ego page)
    let globalScrollElement = $(".rain-scroll-wrap");
    let globalScrollScene = [];

    function globalScroll() {
        if (globalScrollElement.length) {
            globalScrollX = 0;

            $(".rain-scroll-wrap").each(function (i, obj) {
                if (!$.isEmptyObject(globalScrollScene[i])) {
                    globalScrollScene[i] = globalScrollScene[i].destroy(true);
                }

                //images need to be rendered before setting scroll. The scroll duration is based off the height of the loaded obj + window height.
                var checkImagesLoadedAndApplyScroll = setInterval(function () {
                    let _objHeight = $(obj).outerHeight();

                    if (_objHeight !== 0) {
                        let _objHeight = $(obj).outerHeight();
                        let _items = $(obj).find("li");
                        let _objWidth = 0;

                        $.each(_items, function(_i, _obj) {
                            _objWidth += $(_obj).outerWidth();
                        });


                        globalScrollScene[i] = new ScrollMagic.Scene({
                            triggerElement: obj,
                            triggerHook: "onEnter",
                            duration: window.outerHeight + _objHeight
                        })
                            .setTween(obj, {x: -_objWidth - (window.outerWidth / 2) - (window.outerHeight / 2) - (_objHeight / 2), ease: Linear.easeNone})
                            // .addIndicators()
                            .addTo(controller);

                        clearInterval(checkImagesLoadedAndApplyScroll);
                    }
                }, 50);

                setTimeout(function () {
                    clearInterval(checkImagesLoadedAndApplyScroll);
                }, 30000);
            });
        }
    }

    globalScroll();

    //
    // Header
    //
    let stickyHeaderScene = new ScrollMagic.Scene({
        triggerElement: "body",
        triggerHook: 0
    })
        .addTo(controller)
        .on("enter", function () {
            $(".rain-header").addClass("rain-header-sticky");
        })
        .on("leave", function () {
            $(".rain-header").removeClass("rain-header-sticky");
        });

    function dynamicHeaderOffset() {
        stickyHeaderScene.offset($("main").offset().top + 1);
    }

    dynamicHeaderOffset();

    //
    // Nav
    //
    $(".rain-header__col-hamburger").click(function (e) {
        e.preventDefault();
        $("body").addClass("no-scroll");

        $(this).toggleClass("rain-header__col-hamburger-closed rain-header__col-hamburger-open");
        $(".rain-header").toggleClass("rain-header-nav-active").removeClass("rain-header-search-active");
        buildNavPreviews("nav-home");

        if (!$(".rain-header").hasClass("rain-header-nav-active") && !$(".rain-header").hasClass("rain-header-search-active")) {
            $("body").removeClass("no-scroll");
        }

        adjustHeaderHeightForEyebrow();
    });

    $(".rain-header-nav__col-left-link").mouseover(function (e) {
        buildNavPreviews($(e.target).data("nav"));
    });

    function buildNavPreviews(_class) { //adjust this to build all once nav is expanded and hover just swaps out divs.
        $(".rain-header-nav__col-right-container").removeClass("active");
        $("." + _class).addClass("active");
    }

    $(".rain-header-contactus, .rain-header-nav__col-left-btn .rain-btn").click(function(e) {
        anchorContactUsNav(e);
    });

    function anchorContactUsNav(e) {
        // if on hypeher page, anchor to the contact form instead of linking to the contact page.
        if ($(".page-hypeher .rain-contact").length) {
            e.preventDefault();
            hideAllNavElements();

            $root.animate({
                scrollTop: $(".page-hypeher .rain-contact").offset().top - 64
            }, 300);
        }
    }

    $(".rain-header-nav__col-left-subnav-link-expand").click(function() {
        let _thisParent = $(this).closest(".rain-header-nav__col-left-subnav");
        _thisParent.toggleClass("active");
        _thisParent.find(".rain-header-nav__col-left-subnav-menu").slideToggle();
    });

    //
    // Search
    //
    $(".rain-header-search").click(function (e) {
        e.preventDefault();
        $("body").addClass("no-scroll");
        $(".rain-header").toggleClass("rain-header-search-active");
        setTimeout(function () {
            $(".search-field").focus();
        }, 301);

        if (!$(".rain-header").hasClass("rain-header-nav-active") && !$(".rain-header").hasClass("rain-header-search-active")) {
            $("body").removeClass("no-scroll");
        }
    });

    $(".rain-header-search-form-btn-close").click(function (e) {
        e.preventDefault();
        $(".rain-header").removeClass("rain-header-search-active");
    });

    $(".rain-header-search-form-btn-search").click(function (e) {
        e.preventDefault();
        $("#searchForm").submit();
    });

    //Search Form Submit
    $("#searchForm").submit(function (e) {
        e.preventDefault();
        let _searchValue = $(this).find(".search-field").val();
        _searchValue = $.trim(_searchValue); // trim whitespace
        _searchValue = _searchValue.replace(/[^a-zA-Z 0-9]+/g, ''); //remove non alphanumeric
        _searchValue = _searchValue.replace(/\s+/g, '-').toLowerCase(); //replace spaces with a -
        window.location = DOMAIN + "/search/" + _searchValue + "/";
    });

    function hideAllNavElements() {
        $(".rain-header").removeClass("rain-header-search-active rain-header-nav-active");
        $(".rain-header__col-hamburger").removeClass("rain-header__col-hamburger-open").addClass("rain-header__col-hamburger-closed");
        $("body").removeClass("no-scroll");
        $(".rain-header-nav__col-left-subnav").removeClass("active");
        $(".rain-header-nav__col-left-subnav-menu").slideUp();
    }

    //
    // Home
    //

    //Case Studies Detail Slider
    let caseStudiesDetailsSwiper = new Swiper(".caseStudiesDetailsSwiper", {
        effect: 'fade',
        allowTouchMove: false,
        speed: 500,
        fadeEffect: {
            crossFade: true
        }
    });

    let caseStudiesImageSwiperOptions = {};
    let caseStudiesImageSwiper = false;
    let caseStudiesImageSwiperReinitializeNeeded = true;
    let caseStudiesImageSwiperFirstInit = true;
    let windowWidthMobileBreakpointHit = _windowWidth;

    //Case Studies Parameters. Swiper js does not allow creativeEffect & effect in breakpoints.
    function caseStudiesImageSwiperOptionsF() {
        if ($(".caseStudiesDetailsSwiper").length) {
            if (_windowWidth > 767 && windowWidthMobileBreakpointHit <= 767 || _windowWidth > 767 && caseStudiesImageSwiperFirstInit) {
                caseStudiesImageSwiperOptions = {
                    grabCursor: true,
                    effect: "creative",
                    slidesPerView: 1.75,
                    loop: true,
                    watchSlidesProgress: true,
                    speed: 500,
                    creativeEffect: {
                        prev: {
                            translate: [0, 0, -1500],
                        },
                        next: {
                            opacity: .3,
                            scale: .75,
                            translate: ["100%", 0, 0]
                        },
                        limitProgress: 2
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                    thumbs: {
                        swiper: caseStudiesDetailsSwiper,
                    }
                }

                windowWidthMobileBreakpointHit = _windowWidth;
                caseStudiesImageSwiperReinitializeNeeded = true;

            } else if (_windowWidth <= 767 && windowWidthMobileBreakpointHit > 767 || _windowWidth <= 767 && caseStudiesImageSwiperFirstInit) {
                caseStudiesImageSwiperOptions = {
                    slidesPerView: 1.10,
                    loop: true,
                    pagination: false,
                    speed: 500
                }

                windowWidthMobileBreakpointHit = _windowWidth;
                caseStudiesImageSwiperReinitializeNeeded = true;
            }

            caseStudiesImageSwiperFirstInit = false;

            if (caseStudiesImageSwiper !== false && caseStudiesImageSwiperReinitializeNeeded) {
                caseStudiesImageSwiper.destroy();
                caseStudiesImageSwiper = new Swiper(".caseStudiesImageSwiper", caseStudiesImageSwiperOptions);
                caseStudiesImageSwiperReinitializeNeeded = false;
            } else if (caseStudiesImageSwiperReinitializeNeeded) {
                caseStudiesImageSwiper = new Swiper(".caseStudiesImageSwiper", caseStudiesImageSwiperOptions);
                caseStudiesImageSwiperReinitializeNeeded = false;
            }
        }
    }

    //Initialized Case Studies Swiper
    caseStudiesImageSwiperOptionsF();

    //Click event for next slide - without this it just directly links to the href
    $(document).on("click", ".caseStudiesImageSwiper .swiper-slide-next", function (e) {
        e.preventDefault();
        caseStudiesImageSwiper.slideNext();
    });

    //Map
    let mapScene = false;
    let mapAnimationPlayed = false;

    if ($(".rain-home-map").length) {
        mapSceneTrigger();
    }

    function mapSceneTrigger() {
        if (_windowWidth > 767 && !mapScene) {
            mapScene = new ScrollMagic.Scene({
                triggerElement: ".rain-home-map__col-map",
                triggerHook: .5,
            })
                .addTo(controller);

            mapScene.on("start", function (event) {
                if (!mapAnimationPlayed) {
                    initializeMapAnimation();
                    mapAnimationPlayed = true;
                }
            });
        } else if (_windowWidth <= 767 && mapScene) {
            mapScene = mapScene.destroy(true);
        }
    }

    //About Us
    let aboutUsMaxDragOffset;

    function initializeAboutUsDraggableContent() {
        if ($(".rain-home-about").length) {
            if (aboutUsImpetus) {
                aboutUsImpetus.destroy();
            }

            aboutUsMaxDragOffset = $(".rain-home-about__col-right-container-content").length * $(".rain-home-about__col-right-container-content").width() - $(".rain-home-about__col-right").width();

            if (_windowWidth > 767) {
                aboutUsImpetus = new Impetus({
                    source: ".rain-home-about-scroll",
                    initialValues: [0, 0],
                    boundX: [-aboutUsMaxDragOffset, 0],
                    update: function (x) {
                        $(this).css("transform", "translateX(" + x + "px)")
                    }
                });
            } else {
                aboutUsImpetus = new Impetus({
                    source: ".rain-home-about__col-right-container",
                    initialValues: [0, 0],
                    boundX: [-aboutUsMaxDragOffset, 0],
                    update: function (x) {
                        $(this).css("transform", "translateX(" + x + "px)")
                    }
                });
            }
        }
    }

    initializeAboutUsDraggableContent();

    //Agency Summary Counter
    let asPeople;
    let asYears;
    let asClients;
    let asMPlacements;

    if ($("#asPeople").length) {
        asPeople = new countUp.CountUp('asPeople', $("#asPeople").data("countto"));
    }
    if ($("#asYears").length) {
        asYears = new countUp.CountUp('asYears', $("#asYears").data("countto"));
    }
    if ($("#asClients").length) {
        asClients = new countUp.CountUp('asClients', $("#asClients").data("countto"));
    }
    if ($("#asMPlacements").length) {
        asMPlacements = new countUp.CountUp('asMPlacements', $("#asMPlacements").data("countto"));
    }

    if ($(".rain-home-agency").length) {
        let ascScene = new ScrollMagic.Scene({
            triggerElement: ".rain-home-agency",
            triggerHook: .35,
        })
            .addTo(controller);

        ascScene.on("enter", function (event) {
            if ($("#asPeople").length) {
                asPeople.start();
            }
            setTimeout(function () {
                if ($("#asYears").length) {
                    asYears.start();
                }
            }, 250);
            setTimeout(function () {
                if ($("#asClients").length) {
                    asClients.start();
                }
            }, 500);
            setTimeout(function () {
                if ($("#asMPlacements").length) {
                    asMPlacements.start();
                }
            }, 750);
        });
    }

    //
    //Our Work
    //

    //Featured Case Studies
    let ourWorkSwiper = new Swiper(".our-work-swiper", {
        loop: true,
        pagination: {
            el: ".rain-ow-fw__col-right-wrp .swiper-pagination",
            clickable: true
        },
    });

    ourWorkSwiper.on('slideChangeTransitionStart', function (_slide) {
        $($(_slide.$el).find(".rain-ow-fw__col-right-left-counter")).each(function (i, obj) {
            let _thisCounterCount = $(obj).data("countto");

            new countUp.CountUp(obj, _thisCounterCount).start();
        });
    });

    let _ourWorkHeroCounter = [];

    $(".rain-ow-fw__col-right-left-counter").each(function (i, obj) {
        let _thisCounterCount = $(obj).data("countto");

        _ourWorkHeroCounter.push(new countUp.CountUp(obj, _thisCounterCount));
    });

    if ($(".rain-ow-fw__col-right-left-counter").length) {
        let ourWorkHeroScene = new ScrollMagic.Scene({
            triggerElement: ".rain-ow-fw__col-right-right",
            triggerHook: .75,
        })
            .addTo(controller);

        ourWorkHeroScene.on("enter", function (event) {
            $.each(_ourWorkHeroCounter, function (i, obj) {
                scaledSetTimeout(i, obj);
            });
        });
    }

    //Clients We've Scaled
    let clientsWeveScaledImageRowTop = false;
    let clientsWeveScaledImageRowBot = false;

    clientsWeveScaledImageScroll();

    function clientsWeveScaledImageScroll() {
        let rainOWCWSClientsTop = $(".rain-ow-cws-clients-top");
        let rainOWCWSClientsBot = $(".rain-ow-cws-clients-bot");

        if (clientsWeveScaledImageRowTop) {
            clientsWeveScaledImageRowTop.destroy(true);
        }

        if (clientsWeveScaledImageRowBot) {
            clientsWeveScaledImageRowBot.destroy(true);
        }

        if (_windowWidth > 767) {
            //Top Row
            if (rainOWCWSClientsTop.length) {
                clientsWeveScaledImageRowTop = new ScrollMagic.Scene({
                    triggerElement: ".rain-ow-cws-clients-top",
                    triggerHook: "onEnter",
                    duration: "150%"
                })
                    .setTween(rainOWCWSClientsTop, {x: "-15%", ease: Linear.easeNone})
                    .addTo(controller);
            }

            //Bot Row
            if (rainOWCWSClientsBot.length) {
                clientsWeveScaledImageRowBot = new ScrollMagic.Scene({
                    triggerElement: ".rain-ow-cws-clients-bot",
                    triggerHook: "onEnter",
                    duration: "150%"
                })
                    .setTween(rainOWCWSClientsBot, {x: "15%", ease: Linear.easeNone})
                    .addTo(controller);
            }
        }
    }

    //Vimeo Videos - GTM Tracking
    const iframes = document.querySelectorAll('iframe');
    const vimeoPlayers = [];
    iframes.forEach(function(el,index){
        var iframeURL = el.getAttribute('src');
        if (iframeURL.includes('vimeo') && !iframeURL.includes('background=1')){
            vimeoPlayers[index] = new Vimeo.Player(el);
            vimeoPlayers[index].on('play', function(){
                vimeoPlayers[index].getVideoId().then(function(id){
                    // GTM: dataLayer Push
						  pushGTM('play-vimeo',{
							'action' : 'Play',
							'video_id' : id
						  });
                }).catch(function(error){
                    // an error occurred
                });
            });
        }
    });



    //Testimonials Swiper
    let testimonialSwiperOptions = {};
    let testimonialSwiper = false;
    let testimonialSwiperReinitializeNeeded = true;
    let testimonialSwiperFirstInit = true;
    let testimonialWindowWidthMobileBreakpointHit = _windowWidth;

    //Testimonials Swiper. Swiper js does not allow creativeEffect & effect in breakpoints.
    function testimonalSwiperOptionsF() {
        if ($(".testimonialsSwiper").length) {
            if (_windowWidth > 767 && testimonialWindowWidthMobileBreakpointHit <= 767 || _windowWidth > 767 && testimonialSwiperFirstInit) {
                testimonialSwiperOptions = {
                    grabCursor: true,
                    effect: "creative",
                    slidesPerView: 1.75,
                    loop: true,
                    watchSlidesProgress: true,
                    creativeEffect: {
                        prev: {
                            translate: [0, 0, -1500],
                        },
                        next: {
                            opacity: .5,
                            scale: .75,
                            translate: ["100%", '50%', 0]
                        },
                        limitProgress: 2
                    },
                    pagination: {
                        el: ".testimonialsSwiper-container .swiper-pagination",
                        clickable: true,
                    }
                }

                testimonialWindowWidthMobileBreakpointHit = _windowWidth;
                testimonialSwiperReinitializeNeeded = true;

            } else if (_windowWidth <= 767 && testimonialWindowWidthMobileBreakpointHit > 767 || _windowWidth <= 767 && testimonialSwiperFirstInit) {
                testimonialSwiperOptions = {
                    slidesPerView: 1,
                    loop: true,
                    pagination: {
                        el: ".testimonialsSwiper-container .swiper-pagination",
                        clickable: true,
                    }
                }

                testimonialWindowWidthMobileBreakpointHit = _windowWidth;
                testimonialSwiperReinitializeNeeded = true;
            }

            testimonialSwiperFirstInit = false;

            if (testimonialSwiper !== false && testimonialSwiperReinitializeNeeded) {
                testimonialSwiper.destroy();
                testimonialSwiper = new Swiper(".testimonialsSwiper", testimonialSwiperOptions);
                testimonialSwiperReinitializeNeeded = false;
            } else if (testimonialSwiperReinitializeNeeded) {
                testimonialSwiper = new Swiper(".testimonialsSwiper", testimonialSwiperOptions);
                testimonialSwiperReinitializeNeeded = false;
            }

            if (_windowWidth > 767) {
                setBlurEffect(testimonialSwiper, 8);
            }
        }
    }

    //Initialized Case Studies Swiper
    testimonalSwiperOptionsF();

    //
    // Our Work Details
    //

    //Results Overview
    let _resultsOverviewCounter = [];

    $(".rain-owd-results__col-right-col").each(function (i, obj) {
        let _thisCounter = $(obj).find("span");
        let _thisCounterCount = _thisCounter.data("countto");
        let _thisCounterId = _thisCounter.attr("id");
        let _thisCounterDecimal = _thisCounterCount % 1 != 0 ? 1 : 0;

        _resultsOverviewCounter.push(new countUp.CountUp(_thisCounterId, _thisCounterCount, {decimalPlaces: _thisCounterDecimal}));
    });

    if ($(".rain-owd-results").length) {
        let rowdrScene = new ScrollMagic.Scene({
            triggerElement: ".rain-owd-results",
            triggerHook: .35,
        })
            .addTo(controller);

        rowdrScene.on("enter", function (event) {
            $.each(_resultsOverviewCounter, function (i, obj) {
                scaledSetTimeout(i, obj);
            });
        });
    }

    function scaledSetTimeout(i, obj) {
        setTimeout(function () {
            obj.start();
        }, i * 125);
    }

    //Creative Solutions
    new ScrollMagic.Scene({
        triggerElement: ".rain-owd-solutions__col-right",
        triggerHook: "onEnter",
        duration: "150%"
    })
        .setTween(".rain-owd-solutions__col-right-col-left img", {y: "-25%", ease: Linear.easeNone})
        .addTo(controller);

    new ScrollMagic.Scene({
        triggerElement: ".rain-owd-solutions__col-right",
        triggerHook: "onEnter",
        duration: "150%"
    })
        .setTween(".rain-owd-solutions__col-right-col-right img", {y: "-50%", ease: Linear.easeNone})
        .addTo(controller);

    //Main Image Zoom on scroll
    let _owdMainImage = $(".rain-owd-landing-full picture img");

    new ScrollMagic.Scene({
        triggerElement: ".rain-owd-landing-full picture",
        triggerHook: 1,
        duration: window.outerHeight + $(".rain-owd-landing-full picture").outerHeight()
    })
        .addTo(controller)
        .on("progress", function (event) {
            _owdMainImage.css({'transform' : 'translate(-50%, -50%) scale(' + (1 + event.progress * .125) + ')'});
        })

    //Draggable section
    let _owdDragOffset;

    function initializeOWDDraggableContent() {
        if ($(".rain-owd-drag").length) {
            if (owdDragImpetus) {
                owdDragImpetus.destroy();
            }

            _owdDragOffset = $(".rain-owd-drag__col-img div").length * $(".rain-owd-drag__col-img-wrap").width() - $(".rain-owd-drag__col-img-container").width();

            owdDragImpetus = new Impetus({
                source: ".rain-owd-drag-container",
                initialValues: [0, 0],
                boundX: [-_owdDragOffset, 0],
                update: function (x) {
                    $(this).css("transform", "translateX(" + x + "px)");
                }
            });
        }
    }

    initializeOWDDraggableContent();

    //Pinning "rain-owd-videos__col-left-pin"
    let setVideosLeftCopyPinScene = [];

    function setVideosLeftCopyPin() {
        $(".rain-owd-videos__col-left-pin").each(function (i, obj) {
            if (!$.isEmptyObject(setVideosLeftCopyPinScene[i])) {
                setVideosLeftCopyPinScene[i] = setVideosLeftCopyPinScene[i].destroy(true);
            }

            let _closestLeftColumn = $(obj).closest(".rain-owd-videos__col-left");

            if (_windowWidth > 767 && $(obj).outerHeight() < $(obj).closest(".row").find(".rain-owd-videos__col-right").outerHeight()) {
                setVideosLeftCopyPinScene[i] = new ScrollMagic.Scene({
                    triggerElement: _closestLeftColumn[0],
                    triggerHook: .3,
                    duration: _closestLeftColumn.outerHeight() - $(obj).closest(".rain-owd-videos__col-left-pin").outerHeight()
                })
                    .setPin(obj)
                    .addTo(controller);
            }
        });
    }

    setVideosLeftCopyPin();


    //
    //Expertise
    //

    //Accordion
    $(".mcsa-anchor").click(function (e) {
        e.preventDefault();
        let closestRow = $(this).closest(".row");
        let closestRowCollapsableColumn = closestRow.find(".rain-expertise-mcsa__col-right");

        $(".rain-expertise-mcsa__col-right").slideUp();
        $(".rain-expertise-mcsa__col-left .h2").removeClass("active");

        if (!closestRowCollapsableColumn.is(":visible")) {
            closestRowCollapsableColumn.slideDown(function () {
                let headerHeight = $('.rain-header').outerHeight();
                $('html,body').animate({
                    scrollTop: closestRow.offset().top - headerHeight
                }, 300);
            });
            closestRow.find(".h2").addClass("active");
        }
    });

    //
    //Insights & Updates
    //

    //Selectmenu open/close
    $(".rain-selectmenu").click(function (e) {
        $(this).toggleClass("active");
        $(this).parent().find(".rain-selectmenu-options").toggleClass("active");
    });

    function hideSelectmenu() {
        $(".rain-selectmenu, .rain-selectmenu-options").removeClass("active");
    }

    //Detects a change in the checkbox. If >767 it will run the filter function
    $(".rain-iu-selector .rain-checkbox").change(function (e) {
        if ($(window).outerWidth() > 767) {
            filterMixedMedia($(".rain-iu-selector__desktop .rain-checkbox input:checked").attr("value"));
        }
    });

    //This is the checkbox/selectmenu functionality for mobile. <= 767 Clicking submit is required for this to run.
    $("#js-mixed-media-filter").click(function (e) {
        e.preventDefault();

        hideSelectmenu();

        filterMixedMedia($(".rain-iu-selector__mobile .rain-checkbox input:checked").attr("value"));
    });

    //This is where we can filter the media.
    function filterMixedMedia(_slug) {
        if (_slug === "all") {
            window.location.href = window.location.origin + "/insights-updates/";
        } else {
            window.location.href = window.location.origin + "/insights-updates/topic/" + _slug + "/";
        }
    }

    //Show More
    $("#js-mixed-media-show-more").click(function (e) {
        e.preventDefault();
        moreArticlesLoadMore();
    });

    function moreArticlesLoadMore() {
        let simpleBlogRow = ".rain-iu-blog-simple-anchor-container";
        let _loadMoreCount = $("#js-mixed-media-show-more").data("load-more");
        let _totalCount = $(simpleBlogRow).length;
        let _loopCount = 1;

        $(simpleBlogRow + ":not(.active)").each(function (i, obj) {
            if (_loopCount <= _loadMoreCount) {
                $(obj).addClass("active");
            }
            _loopCount++;
        });

        let _loadedCount = $(simpleBlogRow + ".active").length;

        $("#js-mixed-media-loaded-count").text(_loadedCount);

        if (_loadedCount === _totalCount) {
            $(this).hide();
        }
    }

    moreArticlesLoadMore();


    // PDF Download (Media Impact Reports)
    $("a.pdf.download").click(function(e){
        e.preventDefault();
        var file = $(this).data('file');
        var link = document.createElement('a');
        link.href = DOMAIN+"/wp-content/uploads/"+file;
        link.download = file;
        link.click();
        link.remove();
    });


    //
    //Careers
    //

    //Available Positions
    $("#js-careers-filter").click(function(e) {
        e.preventDefault();
        let searchArray = [];
        let searchString = "";

        if ($(".rain-careers-ap .rain-checkbox input[type=checkbox]").length !== $(".rain-careers-ap .rain-checkbox input[type=checkbox]:checked").length) {
            $(".rain-careers-ap .rain-checkbox input[type=checkbox]").each(function (i, obj) {
                if (this.checked) {
                    searchArray.push(this.value);
							// GTM: dataLayer Push
							pushGTM('career-search',{
								'search-filter' : this.value
							});
                }
            });
        }

        if (searchArray.length) {
            searchArray.sort();
            $.each(searchArray, function(i, obj) {
                searchString += searchString ? "_" + obj : obj;
            });

            window.location = DOMAIN + "/careers/" + searchString + "/";
        } else {
            window.location = $(".rain-careers-ap__col-skip .rain-link").attr("href");
        }
    });

    //Global Drag
    let _dragOffset;
    let _slideDragged = false;

    function initializeDraggableContent() {
        if ($(".rain-drag").length) {
            if (careersCultureImpetus) {
                careersCultureImpetus.destroy();
            }

            if (_windowWidth > 767) {
                _dragOffset = $(".rain-drag__col-img div").length * $(".rain-drag__col-img-wrap").width() - $(".rain-drag__col-img-container").width();

                careersCultureImpetus = new Impetus({
                    source: ".rain-drag-container",
                    initialValues: [0, 0],
                    boundX: [-_dragOffset, 0],
                    update: function (x) {
                        $(this).css("transform", "translateX(" + x + "px)");

                        if (_slideDragged) {
                            $(".rain-drag__col-hint p").fadeOut();
                        }

                        _slideDragged = true;
                    }
                });
            }

            //makes sure the height of "Drag to view more" is set to the same as the first image in the draggable container
            $(".rain-drag__col-hint").height($(".rain-drag__col-img-wrap:first").height());
        }
    }

    initializeDraggableContent();

    //
    //Culture
    //

    //Drawer
    $(".js-load-culture-drawer").click(function (e) {
        e.preventDefault();
        closeCultureDrawer();

        let _thisDrawer = $(this).data("drawer");
        $("#" + _thisDrawer).addClass("active");
        $("body").addClass("no-scroll");
    });

    $(".rain-culture-drawer-close").click(function (e) {
        e.preventDefault();
        closeCultureDrawer();
    });

    //Closes culture drawer on click that is not the drawer itself
    $(document).on("click", function (e) {
        if ($(".rain-culture-drawer").hasClass("active") && !$(e.target).parents().hasClass("rain-culture-drawer")) {
            closeCultureDrawer();
        }
    });

    function closeCultureDrawer() {
        $(".rain-culture-drawer").removeClass("active");
        $("body").removeClass("no-scroll");
    }

    //Crew
    let crewScenes = [];

    function setCrewScenes() {
        $(".crew-sticky-col").each(function (i, obj) {
            if (crewScenes[i]) {
                crewScenes[i] = crewScenes[i].destroy(true);
            }

            if (_windowWidth > 767) {
                crewScenes[i] = new ScrollMagic.Scene({
                    triggerElement: obj,
                    duration: ($(".crew-sticky-row").outerHeight() - $(".crew-col").outerHeight()) - $(obj).position().top
                })
                    .setPin($(obj).find(".crew-sticky")[0])
                    .addTo(controller);
            }
        });
    }

    setCrewScenes();

    //
    //Article Details
    //

    //Social Pin with page
    let articleDetailSocialPin = false;

    function setArticleDetailSocialPin() {
        let rADCSIHeight = $(".rain-article-details__col-social-inner").outerHeight();

        if (articleDetailSocialPin) {
            articleDetailSocialPin = articleDetailSocialPin.destroy(true);
        }

        if (_windowWidth > 767) {
            articleDetailSocialPin = new ScrollMagic.Scene({
                triggerElement: ".rain-article-details__col-content",
                duration: $(".rain-article-details__col-content").outerHeight() - rADCSIHeight,
                offset: .5 + (rADCSIHeight / 2)
            })
                .setPin("#rain-article-detail-social-pin")
                .addTo(controller);
        }
    }

    setArticleDetailSocialPin();

    //
    //Contact Us
    //

    //Selectmenu for normal form
    $("#js-contact-us-selectmenu").selectmenu({
        classes: {
            "ui-selectmenu-button": "placeholder"
        },
        change: function (event, ui) {
            let _thisVal = $(this).val();

            if (_thisVal === "Advertiser seeking agency partnership") {
                thankYouMessage("1");
                showNormalFormSelectMenuOptions(".rain-contact-step-2-1", ".rain-contact-step-2-2, .rain-contact-step-2-3");
            } else if (_thisVal === "Media vendor seeking partnership") {
                thankYouMessage("2");
                showNormalFormSelectMenuOptions(".rain-contact-step-2-2", ".rain-contact-step-2-1, .rain-contact-step-2-3");
            } else if (_thisVal === "Seeking employment" || _thisVal === "Other") {
                thankYouMessage("3");
                showNormalFormSelectMenuOptions(".rain-contact-step-2-3", ".rain-contact-step-2-1, .rain-contact-step-2-2");
            }

            $("#js-contact-us-selectmenu-button").removeClass("placeholder");
            removeActiveChecboxes();
        }
    });

    function removeActiveChecboxes() {
        $(".rain-contact .rain-checkbox input[type=checkbox]").prop( "checked", false );
    }

    //Selectmenu hide/show section for normal form
    function showNormalFormSelectMenuOptions(_show, _hide) {
        $(_hide).slideUp(function () {
            $(_show).slideDown();
        });
    }

    let _currentStep;
    let _currentStepID;
    let _nextStepID;

    $(".js-contact-next").click(function (e) {
        e.preventDefault();
        let _this = $(this);
        _currentStep = _this.closest(".rain-contact-step");
        _currentStepID = _currentStep.attr("data-step");
        _nextStepID = _this.attr("data-show-step");
        contactFormValidation();

			// GTM: dataLayer Push
			pushGTM('contact-form-step',{
				'action' : 'step-completed',
				'step' : _currentStepID
			});

    });

    $(".js-contact-prev").click(function (e) {
        e.preventDefault();
        let _this = $(this);
        _currentStep = _this.closest(".rain-contact-step");
        _currentStepID = _currentStep.attr("data-step");
        _nextStepID = _this.attr("data-show-step");
        removeActiveChecboxes();
        showDesiredStep();
    });

    //Validation
    function contactFormValidation() {
        if ($(".rain-contact-form-interactive").is(":visible")) {
            let _formInteractive = $("#rain-contact-form-interactive");

            let servicesRule = {
                required: {
                    depends: function(element) {
                        return $('.rain-contact-step-1-4-1-checkbox:checked').length === 0;
                    }
                },
            };

            let mediaRule = {
                required: {
                    depends: function(element) {
                        return $('.rain-contact-step-1-4-2-checkbox:checked').length === 0;
                    }
                },
            };

            $(".rain-contact-step-1-4-1-checkbox, .rain-contact-step-1-4-2-checkbox").change(function() {
                _formInteractive.valid();
            });

            _formInteractive.validate({
                errorPlacement: function (label, element) {
                    if (element.is(":checkbox") || element.is(":radio")) {
                        if (element[0].name !== "services2" &&
                            element[0].name !== "services3" &&
                            element[0].name !== "services4" &&
                            element[0].name !== "media2" &&
                            element[0].name !== "media3" &&
                            element[0].name !== "media4" &&
                            element[0].name !== "media5" &&
                            element[0].name !== "media6" &&
                            element[0].name !== "media7") {
                            let _container = $(element).closest(".rain-contact-step-checkbox-container");
                            _container.addClass("error");
                            label.appendTo(_container);
                        }
                    } else {
                        $(element).parent().addClass("error");
                        label.insertAfter(element);
                    }
                },
                success: function (label, element) {
                    $(element).parent().removeClass("error");
                    label.remove();
                },
                rules: {
                    "email": {
                        email: true
                    },
                    "services1": servicesRule,
                    "services2": servicesRule,
                    "services3": servicesRule,
                    "services4": servicesRule,
                    "media1": mediaRule,
                    "media2": mediaRule,
                    "media3": mediaRule,
                    "media4": mediaRule,
                    "media5": mediaRule,
                    "media6": mediaRule,
                    "media7": mediaRule
                },
                messages: {
                    "name-first": "Oops! You skipped me",
                    "name-last": "Oops! You skipped me",
                    "regarding": "Oops, you skipped me! Please select one.",
                    "email": "This is awkward. That email's invalid.",
                    "services1": "Oops, you skipped me! Please select one.",
						  "companyServicesInteractive": "Oops, you skipped me!",
                    "media1": "Oops, you skipped me! Please select one.",
						  "companyMediaInteractive": "Oops, you skipped me!",
                    "messageOtherInteractive": "Oops, you skipped me!"
                },
                submitHandler: function (form) {
                    submitForm(form);
                }
            });

            if (_formInteractive.valid() == true) {
                //on ajax success?
                if (_nextStepID === "5") {
                    //makes the form submit so we can get into submithandler
                    _formInteractive.submit();
                } else {
                    showDesiredStep();
                }
            }
        } else if ($(".rain-contact-form-normal").is(":visible")) {
            let _formNormal = $("#rain-contact-form-normal");

            let servicesRule = {
                required: {
                    depends: function(element) {
                        return $('.rain-contact-step-2-1-checkbox:checked').length === 0;
                    }
                },
            };

            let mediaRule = {
                required: {
                    depends: function(element) {
                        return $('.rain-contact-step-2-2-checkbox:checked').length === 0;
                    }
                },
            };

            $(".rain-contact-step-2-1-checkbox, .rain-contact-step-2-2-checkbox").change(function() {
                _formNormal.valid();
            });

            _formNormal.validate({
                errorPlacement: function (label, element) {
                    if (element.is(":checkbox") || element.is(":radio")) {
                        if (element[0].name !== "services2" &&
                            element[0].name !== "services3" &&
                            element[0].name !== "services4" &&
                            element[0].name !== "media2" &&
                            element[0].name !== "media3" &&
                            element[0].name !== "media4" &&
                            element[0].name !== "media5" &&
                            element[0].name !== "media6" &&
                            element[0].name !== "media7") {
                            let _container = $(element).closest(".rain-contact-step-checkbox-container");
                            _container.addClass("error");
                            label.appendTo(_container);
                        }
                    } else if (element.is("select")) {
                        $(element).parent().addClass("error");
                        label.insertAfter(element.parent().find(".ui-selectmenu-button"));
                    } else {
                        $(element).parent().addClass("error");
                        label.insertAfter(element);
                    }
                },
                success: function (label, element) {
                    $(element).parent().removeClass("error");
                    label.remove();
                },
                rules: {
                    "email": {
                        email: true
                    },
                    "companyServices": {
                        required: {
                            depends: function(element) {
                                return $("#js-contact-us-selectmenu").val() === "Advertiser seeking agency partnership";
                            }
                        }
                    },
                    "companyMedia": {
                        required: {
                            depends: function(element) {
                                return $("#js-contact-us-selectmenu").val() === "Media vendor seeking partnership";
                            }
                        }
                    },
                    "services1": servicesRule,
                    "services2": servicesRule,
                    "services3": servicesRule,
                    "services4": servicesRule,
                    "media1": mediaRule,
                    "media2": mediaRule,
                    "media3": mediaRule,
                    "media4": mediaRule,
                    "media5": mediaRule,
                    "media6": mediaRule,
                    "media7": mediaRule,
                    "messageOther": {
                        required: true
                    }
                },
                messages: {
                    "name-first": "Oops! You skipped me",
                    "name-last": "Oops! You skipped me",
                    "regarding": "Oops, you skipped me! Please select one.",
                    "email": "This is awkward. That email's invalid.",
                    "services1": "Oops, you skipped me! Please select one.",
                    "companyServices": "Oops! You skipped me.",
                    "media1": "Oops, you skipped me! Please select one.",
                    "companyMedia": "Oops! You skipped me.",
                    "messageOther": "Oops! You skipped me."
                },
                submitHandler: function (form) {
                    submitForm(form);
                }
            });

            if (_formNormal.valid() == true) {
                //on ajax success?
                if (_nextStepID === "5") {
                    //makes the form submit so we can get into submithandler
                    _formNormal.submit();
                } else {
                    showDesiredStep();
                }
            }
        }
    }

    function submitForm(form) {
        var postData = $(form).serializeArray();
        postData.push({name: 'hostname', value: document.location.hostname});

        // Google reCAPTCHA
        grecaptcha.ready(function(){
            grecaptcha.execute('6LfFWSIaAAAAABkwtFshM6P4TnY3rVFlkQoJ94ky', {action: 'jotformSubmit'}).then(function (token) {
                postData.push({name: 'token', value: token});
                postData.push({name: 'action', value: 'jotformSubmit'});

                $.ajax({
                    type: "POST",
                    url: WPTHEME+"ajax-jotform-contact-us.php",
                    data: postData,
                    cache: false
                }).done(function (data) {
                    removeActiveChecboxes();
                    $(".rain-contact input, .rain-contact textarea").val("");
                    //on success
                    showDesiredStep();
                    //console.log("ajax returned: "+data);
						  //console.log(postData);

							// GTM: dataLayer Push
							var formRegarding = '';
							var formType =  '';
							postData.forEach(function(row){
								if (row.name == 'regarding'){
									formRegarding = row.value.toLowerCase();
								}
								if (row.name == 'form_type'){
									formType = row.value.toLowerCase();
								}
							});
							pushGTM('contact-form-submit',{
								'action' : 'form-submit',
								'type' : formType,
								'regarding' : formRegarding,
							});

                }).fail(function (xhr, textStatus, errorThrown) {
                    //console.log("ajax failure: " + textStatus + "|" + errorThrown);
                });
            });
        });
    }

    function showDesiredStep() {
        //Removes active class from pagination
        $(".rain-contact-footer-pagination-bullet").removeClass("active");

        //Hides current step
        $(_currentStep).fadeOut(function () {
            //Shows current step
            $(".rain-contact-step-" + _nextStepID).fadeIn();

            //Scroll to top of page
            $root.animate({
                scrollTop: $(".rain-contact-step-" + _nextStepID).offset().top - 64
            }, 300);

            if (_nextStepID === "1-4-1" || _nextStepID === "1-4-2" || _nextStepID === "1-4-3") {
                _nextStepID = "1-4";
            } else if (_nextStepID === "5") {
                $(".rain-contact-footer").hide();
                $(".rain-contact").css("min-height", "unset");
            }

            //Adds active class from pagination
            $(".rain-contact-footer-pagination-bullet[data-show-step=" + _nextStepID + "]").addClass("active");
        });
    }

    //Change Event for step 1-4
    $('input[type=radio][name=regarding]').change(function () {
        let _thisNext = $(this).closest(".rain-contact-step").find(".js-contact-next");
        let _thankYouCopy = $(".rain-contact-step-5").find("p");

        //set the correct step & updates the thank you copy
        if (this.value === "Advertiser seeking agency partnership") {
            _thisNext.attr("data-show-step", "1-4-1");
            thankYouMessage("1");
        } else if (this.value === "Media vendor seeking partnership") {
            _thisNext.attr("data-show-step", "1-4-2");
            thankYouMessage("2");
        } else if (this.value === "Seeking employment" || this.value === "Other") {
            _thisNext.attr("data-show-step", "1-4-3");
            thankYouMessage("3");
        }
    });

    function thankYouMessage(_id) {
        let _thankYouCopy = $(".rain-contact-step-5").find("p");

        if (_id === "1") {
            _thankYouCopy.html("Someone from Rain the Growth Agency will contact you shortly.");
        } else if (_id === "2") {
            _thankYouCopy.html("We'll keep your information on file and will reach out if there's an opportunity to work together.");
        } else if (_id === "3") {
            _thankYouCopy.html("We'll follow up with you about your question or comment.");
        }
    }

    //Show Normal Form
    $(".rain-contact-footer-skip-interactive a").click(function (e) {
        e.preventDefault();
        toggleFooter("normal", "interactive");
    });

    //Show interactive form
    $(".rain-contact-footer-skip-normal a").click(function (e) {
        toggleFooter("interactive", "normal");
    });

    function toggleFooter(_show, _hide) {
        //Form pagination fadeout
        if (_hide === "interactive") {
            $(".rain-contact-footer-pagination").fadeOut();
        }

        //Form footer copy toggle
        $(".rain-contact-footer-skip-" + _hide).fadeOut(function () {
            if (_show === "interactive") {
                $(".rain-contact-footer-pagination").fadeIn();
            }

            $(".rain-contact-footer-skip-" + _show).fadeIn();
        });

        //Form toggle
        $(".rain-contact-form-" + _hide).fadeOut(function () {
            $(".rain-contact-form-" + _show).fadeIn();
        });
    }

    //Map Animation function
    function initializeMapAnimation() {
        $(".circle-group").addClass("active");

        document.querySelectorAll(".circle").forEach(function (element) {
            var length = element.getTotalLength();
            element.style.strokeDasharray = length;
            element.style.strokeDashoffset = length;
        });

        document.querySelectorAll(".headquarters").forEach(function (element) {
            element.classList.add("visible");
        });


        function halfCircleAnimationFirst(e, len, promiseResolve) {
            len -= 5;
            if (len < 0) {
                len = 0;
                e.style.strokeDashoffset = len;
                return promiseResolve();
            }

            e.style.strokeDashoffset = len;


            if (len > 0) {
                setTimeout(function () {
                    halfCircleAnimationFirst(e, len, promiseResolve);
                }, 3);
            }
        }

        setTimeout(() => {
            document.querySelectorAll(".circle-group").forEach(function (element, i) {

                setTimeout(() => {
                    let promise = new Promise((resolve, reject) => {
                        let promiseResolve = resolve;
                        let promiseReject = reject;

                        (function () {
                            let pathLength = element.querySelectorAll(".circle")[0].getTotalLength();
                            let halfLength = pathLength;
                            element.querySelectorAll(".circle")[0].style.strokeDasharray = pathLength;
                            element.querySelectorAll(".circle")[0].style.strokeDashoffset = pathLength;
                            halfCircleAnimationFirst(element.querySelectorAll(".circle")[0], pathLength, promiseResolve);
                        })();
                        (function () {
                            let pathLengthSecond = element.querySelectorAll(".circle")[1].getTotalLength();
                            let halfLengthSecond = pathLengthSecond / 2;
                            element.querySelectorAll(".circle")[1].style.strokeDasharray = pathLengthSecond;
                            element.querySelectorAll(".circle")[1].style.strokeDashoffset = pathLengthSecond;
                            halfCircleAnimationFirst(element.querySelectorAll(".circle")[1], pathLengthSecond, promiseResolve);
                        })();
                    });
                    promise
                        .then(
                            result => {
                                //console.log('done');
                                return new Promise((resolve, reject) => { // (*)
                                    element.querySelectorAll(".dot").forEach(function (element, i) {
                                        setTimeout(() => element.classList.add('visible'), i * 50);
                                    });
                                });
                            })
                }, i * 250);
            });
        }, 750)
    }

    // ===================================================================
    // Swiper js HypeHer Brands
    // ===================================================================
    new Swiper(".rain-specialize-hh-brands-swiper-img", {
        grabCursor: true,
        slidesPerView: 1,
        loop: true,
        watchSlidesProgress: true,
        speed: 500,
        pagination: {
            el: ".rain-specialize-hh-brands__col-swiper-img .swiper-pagination",
            clickable: true,
        }
    });

    //Marketing Swiper & Counter
    if ($(".rain-specialize-hh-marketing-swiper .swiper-slide").length > 1) {
        let hypeherMarketingSwiper = new Swiper(".rain-specialize-hh-marketing-swiper", {
            loopedSlides: 3,
            loop: true,
            speed: 500,
            slidesPerView: 1,
            autoHeight: true,
            pagination: {
                el: ".rain-specialize-hh-marketing .swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                768: {
                    autoHeight: false
                }
            }
        });

        hypeherMarketingSwiper.on('slideChangeTransitionEnd', function (e, i) {
            couldHHMarketing();
        });

        hypeherMarketingSwiper.on('slideChange', function (e, i) {
            setTimeout(function () {
                $(".rain-specialize-hh-marketing__col-increase h2 span").html("0");
            }, 25);
        });
    }

    let hhMarketingCounterScene = new ScrollMagic.Scene({
        triggerElement: ".rain-specialize-hh-marketing",
        triggerHook: .5,
    })
        .addTo(controller);

    hhMarketingCounterScene.on("enter", function (event) {
        couldHHMarketing();
    });

    function couldHHMarketing() {
        let hhMarketingCounterElements;

        if ($(".rain-specialize-hh-marketing-swiper .swiper-slide").length > 1) {
            hhMarketingCounterElements = $(".swiper-slide-active .rain-specialize-hh-marketing__col-increase h2 span:visible");
        } else {
            hhMarketingCounterElements = $(".rain-specialize-hh-marketing__col-increase h2 span");
        }

        if (hhMarketingCounterElements.length) {
            hhMarketingCounterElements.each(function (i, obj) {
                let _thisCounterCount = $(obj).data("countto");

                setTimeout(function () {
                    new countUp.CountUp(obj, _thisCounterCount).start();
                }, i * 250);
            });
        }
    }

    /*********************/
    /* About Us Timeline */
    /*********************/
    let setTimelineDotsScene = [];

    function setTimelineDates() {
        $(".rain-about-timeline-dot").each(function (i, obj) {
            if (!$.isEmptyObject(setTimelineDotsScene[i])) {
                setTimelineDotsScene[i] = setTimelineDotsScene[i].destroy(true);
            }

            if (_windowWidth > 767) {
                setTimelineDotsScene[i] = new ScrollMagic.Scene({
                    triggerElement: obj,
                    triggerHook: .3,
                })
                    .addTo(controller);

                setTimelineDotsScene[i]
                    .on("enter", function () {
                        setTimelineYear(obj, "next");
                    })
                    .on("leave", function (event) {
                        setTimelineYear(obj, "prev");
                    });
            }
        });
    }

    function setTimelineYear(_obj, _direction) {
        let _thisCounterCount;
        let _thisCounterStart;
        let objYear = $(_obj).data("year");
        let prevObjYear = $(_obj).prev().data("year");
        let beginningYear = $($(".rain-about-timeline-sticky-wrp")[0]).data("year");

        if (_direction === "next") {
            _thisCounterCount = objYear;
            _thisCounterStart = prevObjYear;
        } else if (_direction === "prev") {
            _thisCounterCount = prevObjYear;
            _thisCounterStart = objYear;
        }

        if (!_thisCounterCount || _thisCounterCount === beginningYear) {
            _thisCounterCount = beginningYear;
            _thisCounterStart = beginningYear;
            $(".rain-about-timeline-sticky-wrp").removeClass("active");
            $(".rain-about-timeline-sticky-wrp-" + beginningYear).addClass("active");
        } else {
            $(".rain-about-timeline-sticky-wrp").removeClass("active");
            $(".rain-about-timeline-sticky-wrp-" + _thisCounterCount).addClass("active");
        }

        new countUp.CountUp("rain-about-timeline-sticky-year", _thisCounterCount, {startVal: _thisCounterStart, useGrouping: false}).start();
    }

    let setTimelinePinScene = [];

    function setTimelinePin() {
        $(".rain-about-timeline-sticky").each(function (i, obj) {
            if (!$.isEmptyObject(setTimelinePinScene[i])) {
                setTimelinePinScene[i] = setTimelinePinScene[i].destroy(true);
            }

            let _closestWrp = $(obj).closest(".rain-about-timeline__col-wrp");

            if (_windowWidth > 767) {
                setTimelinePinScene[i] = new ScrollMagic.Scene({
                    triggerElement: _closestWrp[0],
                    triggerHook: .3,
                    duration: _closestWrp.outerHeight()
                })
                    .setPin(obj)
                    .addTo(controller);
            }
        });

        setTimelineDates();
    }

    setTimelinePin();

    /**************************/
    /* Pinning Image Carousel */
    /**************************/
    let setPinningImageCarouselPinScene = [];

    function pinningImageCarouselPin() {
        let imageCarouselSwiper = new Swiper(".rain-pinned-image-carousel .swiper", {
            slidesPerView: "auto",
            freeMode: true,
            allowTouchMove: false,
            spaceBetween: 30
        });

        $(".rain-pinned-image-carousel").each(function (i, obj) {
            let scrollTrigger = $(obj).find(".scrolltrigger")[0];

            if (!$.isEmptyObject(setPinningImageCarouselPinScene[i])) {
                setPinningImageCarouselPinScene[i] = setPinningImageCarouselPinScene[i].destroy(true);
            }

            setPinningImageCarouselPinScene[i] = new ScrollMagic.Scene({
                triggerElement: scrollTrigger,
                triggerHook: 0,
                offset: -64,
                duration: 3000
            })
                .setPin(scrollTrigger)
                .addTo(controller);

            setPinningImageCarouselPinScene[i]
                .on("progress", function (event) {
                    imageCarouselSwiper.setProgress(event.progress);
                })
        });
    }

    pinningImageCarouselPin();

    /**********************************************************************/
    /* Scrollbar Navigation Swiper (global module) - /expertise/creative/ */
    /**********************************************************************/
    let snSwiper = false;
    function scrollbarNavigationSwiper() {
        if ($(".rain-snswiper").length) {
            if (snSwiper) {
                snSwiper.destroy();
            }

            if (_windowWidth > 767) {
                snSwiper = new Swiper(".rain-snswiper .swiper", {
                    effect: "creative",
                    slidesPerView: "auto",
                    centeredSlides: true,
                    allowTouchMove: false,
                    speed: 500,
                    breakpoints: {
                        1200: {
                            creativeEffect: {
                                prev: {
                                    scale: .45,
                                    translate: ["-100%", 0, 0],
                                    origin: "90%"
                                },
                                next: {
                                    scale: .45,
                                    translate: ["100%", 0, 0],
                                    origin: "10%"
                                }
                            },
                        },
                        768: {
                            creativeEffect: {
                                prev: {
                                    scale: .28,
                                    translate: ["-100%", 0, 0],
                                    origin: "97%"
                                },
                                next: {
                                    scale: .28,
                                    translate: ["100%", 0, 0],
                                    origin: "3%"
                                }
                            },
                        },
                    },
                    scrollbar: {
                        el: ".rain-snswiper .swiper-scrollbar",
                        draggable: true
                    },
                    navigation: {
                        nextEl: ".rain-snswiper .swiper-button-next",
                        prevEl: ".rain-snswiper .swiper-button-prev",
                    },
                });
            } else {
                snSwiper = new Swiper(".rain-snswiper .swiper", {
                    slidesPerView: 1,
                    allowTouchMove: false,
                    speed: 500,
                    scrollbar: {
                        el: ".rain-snswiper .swiper-scrollbar",
                        draggable: true
                    },
                    navigation: {
                        nextEl: ".rain-snswiper .swiper-button-next",
                        prevEl: ".rain-snswiper .swiper-button-prev",
                    },
                });
            }
        }
    }

    scrollbarNavigationSwiper();

});

// ===================================================================
// Global Function to tell what page is viewed based on body class/ID
// ===================================================================
function isPage(a){
    if($('body#'+a).length){return true;}
    else if ($('body').hasClass(a)){return true;}
    else if ($('body').hasClass('page-'+a)){return true;}
    else{return false;}
}

// ===================================================================
// Global Function to event and properties object to GTM dataLayer
// ===================================================================
function pushGTM(eventName,eventProperties){
	if (typeof window.dataLayer !== 'undefined'){
		if (typeof eventName === 'string' && typeof eventProperties === 'object'){
			eventName = eventName.replace(' ','-').replace('_','-');
			window.dataLayer.push({
				'event': 'JS - '+eventName,
				'js-event': eventProperties
			});
		}
	}
}